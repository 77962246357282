// @flow
import { Button } from "@mui/material";
import * as React from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";

export const HeaderHome = (props) => {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  return (
    <div className="bg-graybackground ml-[92px] rounded-xl sm:flex px-9 py-[60px] w-[400px]   justify-center items-center hidden flex-col">
      <h1 className="text-[#313033] px-2 text-[1.3rem]  text-center sm:flex hidden font-MontserratSemiBold self-start mb-4">
        {t("homePage.8")}
      </h1>
      <h2 className="font-MontserratRegular px-2 w-full text-base text-black leading-5">
        {t("homePage.18")}
      </h2>
      {/* <Button className="bg-fontColorB w-full h-[42px] rounded-full text-white font-MontserratMedium text-base mt-5">Call Us</Button> */}
      <a
        href="https://wa.me/905324479299"
        rel="noopener noreferrer"
        target="_blank"
        className=" bg-fontColorB  flex justify-center items-center w-full h-[42px] rounded-full text-white font-MontserratMedium text-base mt-5 "
      >
        <p className=" text-white font-MontserratMedium text-base">
          {t("homePage.10")}
        </p>
      </a>
      <div className="bg-graybackground border-2 w-full h-[42px] rounded-full border-fontColorB  mt-3">
        {/* <Button className=" border-2 w-full border-fontColorB h-full rounded-full  text-fontColorB font-MontserratMedium text-base">Join Now</Button> */}
        <Link
          href={
            isEnglish
              ? "https://coimex.co/pricing?package=exporter"
              : "https://coimex.co/tr/pricing?package=exporter"
          }
          className="border-2 w-full border-fontColorB h-full rounded-full"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="h-full flex justify-center items-center rounded-full"
          >
            <p className="text-fontColorB font-MontserratMedium text-base">
              {t("homePage.11")}
            </p>
          </a>
        </Link>
      </div>
    </div>
  );
};
