import { Divider } from "@mui/material";
import Link from "next/link";
import { useRef, useState } from "react";
import { MenuDrawer } from "../../components/Drawer/CoimexMenu";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "@headlessui/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Disclosure } from "@headlessui/react";
import Flags from "../../components/Flags";
import React from "react";
// import WhatsAppWidget from "react-whatsapp-chat-widget";
// const WhatsAppWidget = require('react-whatsapp-chat-widget');

export function Header(props) {
  const menuDrawer = useRef();
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const links = [
    { id: "1", href: "/pricing?package=importer", label: t("Header.9") },
    { id: "2", href: "/pricing?package=exporter", label: t("Header.10") },
    { id: "3", href: "/pricing?package=Both", label: t("Header.11") },
  ];
  const linksPlatform = [
    { id: "1", href: "/importer-platform", label: t("Header.8") },
    { id: "2", href: "/exporter-platform", label: t("Header.7") },
  ];

  const blockChain = [
    { id: "1", href: "/escrow-safe-payment", label: t("Header.16") },
    { id: "2", href: "/prompt-pay-assurance", label: t("Header.17") },
    { id: "3", href: "/commodity-token-marketplace", label: t("Header.18") },
    { id: "4", href: "/future-blockchain-solutions", label: t("Header.15") },
    { id: "5", href: "https://eximtoken.co/", label: t("Header.14") },

    // { id: "1", href: "https://eximtoken.coimex.co", label: t("Header.13") },
  ];
  async function handleLang(key) {
    i18n.changeLanguage(key);
    router.push(router.asPath, undefined, { locale: key });
    localStorage.setItem("lang", key);
  }
  const { asPath } = useRouter();

  return (
    <Menu>
      <div className={"bg-PrimaryA"}>
        {asPath !== "/waiting-list" && (
          <div className=" h-[55px] flex justify-between m-auto items-center w-11/12 px-1">
            {/* <p className="font-MontserratSemiBold leading-6  text-base">Coimex</p> */}
            <Link href={"/"}>
              <div>
                <img
                  loading="lazy"
                  alt="Coimex"
                  width="100%"
                  height="100%"
                  className="cursor-pointer"
                  src="/images/logoSvg.svg"
                />
              </div>
            </Link>
            <Link href={"https://app.coimex.co"}>
              <p className="font-MontserratSemiBold select-none text-[#38466D] cursor-pointer hidden md:flex leading-6  text-base">
                {t("Header.0")}
              </p>
            </Link>
            <MenuSvg onClick={() => menuDrawer.current.openSheet()} />
          </div>
        )}
        <div
          className={`${
            router.route === "/"
              ? "absolute  bg-white/30 "
              : router.route === "/token-sale"
              ? "absolute bg-white/10 "
              : "bg-PrimaryB"
          }  w-full`}
        >
          <div className=" hidden md:flex w-11/12 m-auto justify-between items-center">
            <div className=" h-[64px]   px-1  flex items-center">
              <Disclosure>
                <Menu>
                  <Menu.Button className="font-MontserratSemiBold ml-1 text-textColor hidden md:flex leading-6  text-bas select-none cursor-pointer">
                    {t("Header.1")}
                  </Menu.Button>
                  <Menu.Items className="h-24 w-64 absolute rounded-md justify-around p-4 flex flex-col self-start mt-12 bg-[#8791A7] z-30">
                    {linksPlatform.map((link) => (
                      /* Use the `active` state to conditionally style the active item. */
                      <Menu.Item
                        className="text-textColor text-base   font-MontserratRegular hover:font-MontserratSemiBold"
                        key={link.href + link.id}
                      >
                        {({ active }) => (
                          <Link key={link.id} href={link.href}>
                            <a {...props}>
                              <span className="text-textColor  cursor-pointer text-base  font-MontserratRegular hover:font-MontserratSemiBold">
                                {link.label}
                              </span>
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </Disclosure>
              <Disclosure>
                <Menu>
                  <Menu.Button className="font-MontserratSemiBold ml-12 text-textColor hidden md:flex leading-6  text-bas select-none cursor-pointer">
                    {t("Header.12")}
                  </Menu.Button>
                  <Menu.Items className="h-52 w-[290px] absolute ml-[125px] rounded-md justify-around p-4 flex flex-col self-start mt-12 bg-[#8791A7] z-30">
                    {blockChain.map((link) => (
                      /* Use the `active` state to conditionally style the active item. */
                      <Menu.Item
                        className="text-textColor text-base   font-MontserratRegular hover:font-MontserratSemiBold"
                        key={link.href + link.id}
                      >
                        {({ active }) => (
                          <Link href={link.href}>
                            <a {...props} key={link.id}>
                              <span className="text-textColor  cursor-pointer text-base  font-MontserratRegular hover:font-MontserratSemiBold">
                                {link.label}
                              </span>
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </Disclosure>
              <Disclosure>
                <Menu>
                  <Menu.Button className="font-MontserratSemiBold ml-12 text-textColor hidden md:flex leading-6  text-bas select-none cursor-pointer">
                    {t("Header.2")}
                  </Menu.Button>
                  <Menu.Items className="h-32 w-64 ml-[125px] absolute rounded-md justify-around p-4 flex flex-col self-start mt-12 bg-[#8791A7] z-30">
                    {links.map((link) => (
                      /* Use the `active` state to conditionally style the active item. */
                      <Menu.Item key={link.href + link.id}>
                        {({ active }) => (
                          <Link key={link.id} href={link.href}>
                            <a {...props}>
                              <span className="text-textColor text-base  cursor-pointer  font-MontserratRegular hover:font-MontserratSemiBold">
                                {link.label}
                              </span>
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </Disclosure>

              <Link href={"/industries"}>
                <a {...props}>
                  <p className=" font-MontserratSemiBold ml-12 text-textColor hidden md:flex leading-6  text-bas select-none cursor-pointer">
                    {t("Header.3")}
                  </p>
                </a>
              </Link>
              <Link href={"/trust"}>
                <a {...props}>
                  <p className="font-MontserratSemiBold ml-12 text-textColor hidden md:flex leading-6  text-bas select-none cursor-pointer">
                    {t("Header.4")}
                  </p>
                </a>
              </Link>
              <a
                href={`https://coimex.co/blog${
                  router.locale === "tr" ? "/tr" : ""
                }`}
                rel="noopener noreferrer"
              >
                <p className="font-MontserratSemiBold ml-12 text-textColor hidden md:flex leading-6  text-bas select-none cursor-pointer">
                  {t("Header.5")}
                </p>
              </a>
              <Link href={"/about-us"}>
                <a {...props}>
                  <p className="font-MontserratSemiBold ml-12 text-textColor hidden md:flex leading-6  text-bas select-none cursor-pointer">
                    {t("Header.6")}
                  </p>
                </a>
              </Link>
            </div>
            <div className="flex items-center">
              {/* flag icon for english */}

              {/* <Flags lan={"en"} handleLanguage={() => handleLang("en")} /> */}
              <p
                onClick={() => handleLang("en")}
                className={`${
                  i18n.language == "en"
                    ? "font-MontserratSemiBold"
                    : "ont-MontserratRegular"
                }   text-textColor hidden md:flex leading-6  text-lg select-none cursor-pointer pl-1`}
              >
                English
              </p>
              <Divider
                orientation="vertical"
                variant="middle"
                className="w-[2px] m-3 h-6 bg-textColor"
              />
              {/* <Flags lan={"tr"} handleLanguage={() => handleLang("tr")} /> */}
              <p
                onClick={() => handleLang("tr")}
                className={`${
                  i18n.language == "tr"
                    ? "font-MontserratSemiBold"
                    : "ont-MontserratRegular"
                }   text-textColor hidden md:flex leading-6  text-lg select-none cursor-pointer pl-1`}
              >
                Türkçe
              </p>
            </div>
          </div>
        </div>
        <MenuDrawer
          blockChain={blockChain}
          linksPlatform={linksPlatform}
          links={links}
          ref={menuDrawer}
        />
      </div>
    </Menu>
  );
}

const MenuSvg = ({ onClick }) => (
  <svg
    onClick={onClick}
    className="cursor-pointer z-10 md:hidden"
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    width="35"
  >
    <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
  </svg>
);
